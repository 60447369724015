const mockOneX = {
  addElement: (e: any) => {},
  removeElement: (e: any) => {},
  Modal: {
    addElement: e => {},
    bindEvents: e => {},
    callModal: (e, n) => {},
    closeButtonClicked: e => {},
    closeButtonSpacebar: e => {},
    continueBtnClicked: () => {},
    defineLanguageModals: () => {},
    hideModal: e => {},
    hideSessionModal: () => {},
    init: () => {},
    preventEscapeKeyAction: e => {},
    removeElement: e => {},
    scrimClick: e => {},
    setFocus: (e, n, t, o) => {},
    showLanguageModal: e => {},
    showModal: (e, n) => {},
    tabController: e => {}
  }
}

export const ONEX = window['oneX'] ? window['oneX'] : mockOneX

export const LOCATION = {
  replace: (url: string) => window.location.replace(url),
  goTo: (url: string) => (location.href = url)
}

export const flowId = crypto.randomUUID()
export const dataLayer = window['dataLayer'] || {}
dataLayer.flowId = flowId
dataLayer.screenChannel = 'policyviewdetails'
